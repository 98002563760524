/*https://codepen.io/macro6461/pen/MdJwOa*/
.slideshow-container-base {
    max-width: 1024px;
    max-height:100vh;
    position: relative;
    margin: 0;
}
.slideshow-container {
    max-width: 100vmin;
    max-height:100vmin;
    position: relative;
    object-fit: contain;
    background: black;
    margin: 0;
}
.choose-picture-0 {
    width: min(760px, 85vw);
    position: relative;
    margin: 0;
}
.slideshow-container-0 {
    max-width: 100vw;
    position: relative;
    margin: 0;
}
.choose-picture-1 {
    width: min(200px, 85vw);
    position: relative;
    margin: 0;
}
.slideshow-container-1 {
    max-width: 300px;
    max-height:100vh;
    position: relative;
    margin: 0;
}
.choose-picture-2 {
    width: min(300px, 85vw);
    position: relative;
    margin: 0;
}
.slideshow-container-2 {
    max-width: 600px;
    max-height:100vh;
    position: relative;
    margin: 0;
}
.choose-picture-3 {
    width: min(400px, 85vw);
    position: relative;
    margin: 0;
}
.slideshow-container-3 {
    max-width: 800px;
    max-height:100vh;
    position: relative;
    margin: 0;
}
.choose-picture-4 {
    width: min(600px, 85vw);
    position: relative;
    margin: 0;
}
.slideshow-container-4 {
    max-width: 1000px;
    max-height:100vh;
    position: relative;
    margin: 0;
}
.choose-picture-5 {
    width: min(600px, 85vw);
    position: relative;
    margin: 0;
}
.slideshow-container-5 {
    max-width: 1200px;
    max-height:100vh;
    position: relative;
    margin: 0;
}
.choose-picture-6 {
    width: min(700px, 85vw);
    position: relative;
    margin: 0;
}
.slideshow-container-6 {
    max-width: 1200px;
    max-height:100vh;
    position: relative;
    margin: 0;
}

.prev,
.next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    margin-top: -22px;
    margin-left: -10px;
    margin-right: -10px;
    padding: 0px;
    color: #222428;
    font-weight: bold;
    font-size: 30px;
    transition: .6s ease;
    border-radius: 0 3px 3px 0
}

.next {
    right: -20px;
    border-radius: 3px 3px 3px 3px
}

.prev {
    left: -20px;
    border-radius: 3px 3px 3px 3px
}

.prev:hover,
.next:hover {
    color: #f2f2f2;
    background-color: rgba(0, 0, 0, 0.8)
}

.text {
    color: #f2f2f2;
    font-size: 15px;
    padding-top: 12px;
    padding-bottom: 12px;
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    background-color: #222428
}

.container { position: relative; }
.container img { display: block; }
.container .icon { position: absolute; top:0; left:0; }
