
.xmenu-block:hover{
   background-color: var(--navbar-item-hover-background-color);
   color: var(--navbar-item-hover-color);
}
.xmenu-block.is-active{
   background-color: var(--primary);
   color: #fff;
}

@media screen and (min-width: 768px) {
.menu {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  vertical-align: top;
  overflow-y: auto;
  max-height: 100vh;
  min-width: 100px;
  max-width: 200px;
  //bottom: 0;
  padding: 60px 30px;
}

.xmenu-block {
  font-size: 1rem;
  display: block;
  padding:  .5em .75em;
  line-height: 1.25;
  color: var(--aside-item-color);
}
.aside {
   display: block;
   margin: 0 auto;
}
.nospace {
}
}
@media screen and (min-width: 600px) and (max-width: 767px) {
.menu>* {
          flex: 0 0 25%;
      }
}
@media screen and (min-width: 460px) and (max-width: 599px) {
.menu>* {
          flex: 0 0 33%;
      }
}
@media screen and (min-width: 0px) and (max-width: 459px) {
.menu>* {
          flex: 0 0 50%;
      }
}
  
  
@media screen and (min-width: 0px) and (max-width: 767px) {
.menu {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  min-height: 30px;
  max-height: 100px;
  max-width: 100vw;
  z-index: 10;
  display:flex;
  flex-wrap: wrap;
  flex-basis: 0;
  overflow-y: visible;
  align-self: flex-start;
  background-color: var(--background-color);
  padding: 10px 0 10px 15px;
}
  
.xmenu-block {
  pointer-events: auto;
  padding:  .5em .75em;
  color: var(--aside-item-color);
}

.aside {
  display:unset;
}
.nospace {
   white-space: nowrap;
}
}


.fa-cog {
  color: red;
}
.fa-read{
  color: white;
}
