
@media screen and (min-width: 768px) {
.scrollable {
  overflow: auto;
  max-height: 98vh;
}
}

@media screen and (min-width: 0px) and (max-width: 767px) {
.scrollable {
  overflow-x: auto;
  overflow-y: auto;
  max-height: 85vh;
}
}

.disable-scrollbars::-webkit-scrollbar {
  background: transparent; /* Chrome/Safari/Webkit */
  width: 0px;
}
    
.disable-scrollbars {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* IE 10+ */
}
