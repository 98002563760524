.StripeElement {
      display: block;
      margin: 5px 0 5px 0;
      width: 100%;
      border-radius: 4px;
      background: white;
      border: 1px solid #e8e8e8;
      box-shadow: none;
      height: 2.5em;
      line-height: 1.5;
      padding-left: calc(0.75em - 1px);
      padding-right: calc(0.75em - 1px);
      padding-bottom: calc(0.5em - 1px);
      padding-top: calc(0.5em - 1px);
  }

  .StripeElement--focus {
     border-color: #3273dc;
     box-shadow: 0 0 0 0.125em rgba(50, 115, 220, 0.25);
     background: white;
    }
