/** Needed to override some bulma definitions that
    are problematic in the editor
**/
ul 
{
 list-style-position: inside;
}
ul li 
{ list-style-type: inherit; 
  list-style-position: inside;
 /* margin-left:1em;*/
}
ol
{
list-style-position: inside;
}
ol li
{
/*margin-left:1em;*/
}
