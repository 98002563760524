/*Styling contenteditable*/
.ctitle {
    display: block;
    font-size: 1.40em;
    margin:0;
    padding: 1%;
    font-weight: 500;
    border: 1px dashed #aaa;
}
.vtitle {
    display: block;
    font-size: 1.40em;
    margin:0;
    padding: 1%;
    font-weight: 500;
    border: none;
}

.cbody {
  min-height: 100px;
  border: 1px dashed #aaa;
  padding: 1%;
  margin: 0;
}
.vbody {
  border: none;
  padding: 1%;
  margin: 0;
}

/*Adding placeholders*/
.ctitle:empty:before {
  content: attr(placeholder);
  display: block;
  color: #aaa;
}
.vtitle:empty:before {
  content: attr(placeholder);
  display: block;
  color: #aaa;
}
.cbody:empty:before {
  content: attr(placeholder);
  display: block;
  color: #aaa;
}
.vbody:empty:before {
  content: attr(placeholder);
  display: block;
  color: #aaa;
}

